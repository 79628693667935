import React from "react"

const Degree = ({ education }) => {
  const { degreeName, schoolName, street, timePeriod, cityCountry } = education
  return (
    <div className="pt-6">
      <h4 className="text-lg font-bold mb-2">
        {degreeName}{" "}
        <span className="text-gray-700 text-xs font-light">({timePeriod})</span>
      </h4>
      <p className="text-md font-bold">{schoolName}</p>
      <p>{street}</p>
      <p>{cityCountry}</p>
    </div>
  )
}
export default Degree
