import React from "react"
import Panel from "./Panel"
import Underline from "./Underline"
import SingleEducation from "./Degree"

const educations = [
  {
    cityCountry: "LU1 3JU, England",
    degreeName: "MSc computers and Internet Application",
    schoolName: "University of Bedfordshire",
    street: "Park Square, Luton, Bedfordshire",
    timePeriod: "Oct 2010 - Nov 2011",
  },
  {
    cityCountry: "Jalandhar, Punjab 144003, India",
    degreeName: "Bachelor of Computer Applications",
    schoolName: "APJ collage of fine arts ",
    street: "New Jawahar Nagar, Mahavir Marg",
    timePeriod: "June 2005 - June 2008",
  },
]

const Degrees = () => {
  return (
    <Panel>
      <h2 className="text-2xl font-bold text-primary-100" id="education">
        Academic history
      </h2>
      <Underline />
      <p>
        I still remember, when heard in my childhood{" "}
        <b>“Education is the key to success”</b>. Only 'Read' and ‘Write’ is not
        education, it only makes you literate not educated. For the complete
        development of person, a person need to have education, knowledge and
        confidence. They all together makes person self dependent, important in
        society, successful in life and a good thinker.
      </p>
      {educations.map((education, i) => (
        <SingleEducation key={i} education={education} />
      ))}
    </Panel>
  )
}
export default Degrees
