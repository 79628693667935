import React from "react"
import Panel from "./Panel"
import Underline from "./Underline"
import TimelineSpacer from "./TimelineSpacer"
import SingleExperience from "./SingleExperience"

const experienceData = [
  {
    address: {
      cityCountry: "1220 Vienna, Austria",
      companyName: "Shpock",
      street: "Donau-City-Straße 1",
    },
    description:
      "Working as Frontend developer in web team of Shpock. As a developer, I am responsible to write Shpock website in Reactjs, Typescript, Graphql, Apollo and other latest technologies.",
    duties: [
      "Develop a Shpock's website (www.shpock.com)",
      "Work on another project for paid customers (plus.shpock.com)",
      "Both projects developed in Reactjs and Typescript",
      "Create stories for each component then use them in Application",
      "Graphql and Apollo are used to clean the data coming from API",
      "Styled components are used to style the elements",
      "Write test with Jest and Enzyme",
      "Work in the squad (Web-squad)",
    ],
    jobTitle: "Frontend Developer",
    startYear: "2018",
    timePeriod: "July 2018 – present",
  },
  {
    address: {
      cityCountry: "1100 Vienna, Austria",
      companyName: "Jaggaer Direct",
      street: "Wienerbergstrasse 11",
    },
    description:
      "Responsible to develop responsive web templates from PNG designs provided by UI Designer. Developing these templates from scratch using HTML5, SCSS/CSS3, JavaScript including jQuery and Bootstrap. Test templates together with UI Designer and other frontend developers and get feedback. Once the template is ready then helping backend developers to integrate them with Backbone.js views.",
    duties: [
      "Convert PSD’s to HTML using Bootstrap SCSS/CSS, Jquery, and JavaScript.",
      "Closely worked with UI designer, backend developers and product managers.",
      "Testing code with different browsers, to ensure cross-browser compatibility.",
      "Using Git for version controlling.",
      "Using Jira cards for tasks and Stories.",
      "Worked in SCRUM team.",
      "Properly document the templates (using Confluence) and comments in the code.",
      "Fix tickets related to frontend and minor issues in the backend.",
      "All code is written in phpStorm (Jetbrains).",
    ],
    jobTitle: "Frontend Developer",
    startYear: "2015",
    timePeriod: "July 2015 – June 2018",
  },
  {
    address: {
      cityCountry: "HULL HU5 3TS, England",
      companyName: "Chanterlands Stores LTD",
      street: "128-130 Chanterlands Avenue",
    },
    duties: [
      "Develop a new online store with Joomla.",
      "Managing SEO, Google Analytics & Web Master.",
      "Managing Domain & Web-Hosting (godaddy.com).",
      "Taking products’ pictures & making changes using Photoshop.",
    ],
    jobTitle: "Frontend Developer",
    startYear: "2017",
    timePeriod: "July 2013 – Dec 2013",
  },
  {
    address: {
      cityCountry: "Yorkshire, HU6 7 RX, England",
      companyName: "University of HULL",
      street: "Cottingham Road",
    },
    duties: [
      "Making changes in HTML & CSS code to make the whole project responsive.",
      "Editing & updating University of Hull’s website using CMS called Immediacy.",
      "Responsible for quality of content.",
      "Find out the broken links and fix them.",
    ],
    jobTitle: "Web Developer",
    startYear: "2017",
    timePeriod: "May 2013 – July 2013",
  },
  {
    address: {
      cityCountry: "Luton, Bedforshire, LU2 0NT, England",
      companyName: "Media66",
      street: "Courtney House, 12 Dudley Street",
    },
    duties: [
      "Develop and maintain responsive websites.",
      "Made car parts' catalogues by using InDesign and Easy Catalogue plugin.",
      "Developed E-Commerce website with Zen Cart.",
      "Taking products’ pictures & making changes using Photoshop.",
    ],
    jobTitle: "Web Designer",
    startYear: "2017",
    timePeriod: "Jan 2012 – Jan 2013",
  },
]

const Experience = () => {
  return (
    <Panel>
      <h2 className="text-2xl font-bold text-primary-100" id="experience">
        Professional experience
      </h2>
      <Underline />

      {experienceData.map((experience, i) => (
        <>
          <SingleExperience key={i} experience={experience} />
          {experienceData.length !== i + 1 && <TimelineSpacer />}
        </>
      ))}
    </Panel>
  )
}
export default Experience
