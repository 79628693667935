import React from "react"

const Panel = ({ children }) => {
  return (
    <div className="pt-8 pr-4 pb-8 pl-4 bg-white shadow rounded-lg h-full text-sm text-primary-200">
      {children}
    </div>
  )
}

export default Panel
