import React from "react"
import Panel from "./Panel"
import Underline from "./Underline"

const ContactMe = () => {
  return (
    <Panel>
      <h2 className="text-2xl font-bold text-primary-100" id="contactMe">
        Contact me
      </h2>
      <Underline />
      <p>
        Feel free to contact me via Linkedin, Xing or Send an email. I will try
        to reply you as soon as possible.
      </p>
    </Panel>
  )
}
export default ContactMe
