import React from "react"

const Layout = ({ children }) => {
  return (
    <main
      className="border-top-2 border-primary-100 py-8 bg-gray-200 relative"
      style={{
        minHeight: "100vh",
      }}
    >
      <div className="flex flex-wrap">
        <div className="w-full lg:max-w-5xl my-0 mx-auto">
          <div className="flex flex-wrap">{children}</div>
        </div>
      </div>
    </main>
  )
}

export default Layout
