import React from "react"
import Panel from "./Panel"
import { Link } from "gatsby"

const CopyRight = () => {
  const currentYear = new Date().getFullYear()

  return (
    <Panel>
      <span>© {currentYear} </span>
      <Link to="/">Prince Sodhi</Link>
    </Panel>
  )
}
export default CopyRight
