import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import defaultImage from "../images/default-og-image.jpg"

const SEO = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const { title, description } = site.siteMetadata

  return (
    <Helmet title={title}>
      <meta property="fb:app_id" content="346536922720537" />

      <meta property="og:url" content="https://www.princesodhi.com" />
      <meta property="og:type" content="website" />

      <meta
        property="og:title"
        content="Prince Sodhi - Frontend developer in Vienna, Austria"
      />
      <meta property="og:description" content={description} />

      <meta property="og:image" content={defaultImage} />
      <meta property="og:image:secure_url" content={defaultImage} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />

      <meta property="og:locale" content="en_GB" />
      <meta name="description" content={description} />
      <meta name="image" content={defaultImage} />

      <meta itemprop="name" content={title} />
      <meta itemprop="description" content={description} />
      <meta itemprop="image" content={defaultImage} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="Prince Sodhi" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={defaultImage} />
    </Helmet>
  )
}

export default SEO
