import React from "react"
import princeSodhiImg from "../images/prince-sodhi.jpg"
import IconGithub from "../../assets/icons/github.svg"
import IconLinkedin from "../../assets/icons/linkedin.svg"
import IconStackoverflow from "../../assets/icons/stackoverflow.svg"

const Sidebar = () => {
  return (
    <div className="w-full md:w-4/12 lg:w-3/12 px-4 h-full mb-3 ">
      <div className="bg-white rounded-lg shadow text-primary-100 md:fixed">
        <div className="social-bar justify-between w-10/12 mt-0 mb-1 mx-auto py-2 px-4 bg-primary-100 rounded-bl-lg rounded-br-lg text-white flex">
          <a
            href="https://github.com/sodhisaab"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconGithub />
          </a>
          <a
            href="https://www.linkedin.com/in/princesodhi"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconLinkedin />
        </a>
          <a
            href="https://stackoverflow.com/users/1636377/sodhi-saab"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconStackoverflow />
          </a>
        </div>
        <div className="text-primary p-4">
          <img
            className="w-20 h-20 rounded-full m-auto object-cover shadow"
            src={princeSodhiImg}
            alt="Prince Sodhi"
          />
          <div className="text-primary-200 text-center">
            <p className="font-bold text-xl">Prince Sodhi</p>
            <p>Frontend developer</p>           
          </div>
          <hr className="mt-4" />
        </div>
        <ul className="pb-6 cursor-pointer">
          <li className="hover:bg-primary-100 hover:text-white">
            <a className="px-4 py-2 w-full block" href="#aboutMe">
              About me
            </a>
          </li>
          <li className="hover:bg-primary-100 hover:text-white">
            <a className="px-4 py-2 w-full block" href="#experience">
              Professional experience
            </a>
          </li>
          <li className="hover:bg-primary-100 hover:text-white">
            <a className="px-4 py-2 w-full block" href="#education">
              {" "}
              Academic history
            </a>
          </li>
          <li className="hover:bg-primary-100 hover:text-white">
            <a className="px-4 py-2 w-full block" href="#skills">
              Skills
            </a>
          </li>
          <li className="hover:bg-primary-100 hover:text-white">
            <a className="px-4 py-2 w-full block" href="#contactMe">
              Contact me
            </a>
          </li>
        </ul>
      </div>
    </div>
  )
}
export default Sidebar
