import React from "react"
import Panel from "./Panel"
import Underline from "./Underline"
import deutscheLanguage from "../images/deutsche-language.png"
import englishLanguage from "../images/english-language.png"
import hindiLanguage from "../images/hindi-language.png"

const languageImages = {
  "de-at": deutscheLanguage,
  "en-gb": englishLanguage,
  "hi-in": hindiLanguage,
}
const languages = ["en-gb", "de-at", "hi-in"]

const professionalSkills = [
  "JavaScript",
  "React",
  "GraphQL",
  "Apollo",
  "Styled Components",
  "Storybook",
  "NPM & Yarn",
  "Jest",
  "Enzyme",
  "HTML5",
  "CSS3 and SCSS",
  "Git",
  "Jira",
  "PhpStrom & VS Code",
  "Jquery",
  "Bootstrap",
  "Photoshop",
  "BackboneJS (Beginner)",
  "Angular (Beginner)",
  "Confluence",
  "GoDaddy",
  "MS Office",
  "SAP Fiori",
  "Build (Build.me)",
  "Invision",
]

const Skills = () => {
  return (
    <Panel>
      <h2 className="text-2xl font-bold text-primary-100" id="skills">
        Skills
      </h2>
      <Underline />
      <div>
        <p className="mb-6">
          Skills or talent make you able to perform certain tasks. My job skills
          allow me to works perfectly in my professional life. Whereas my
          language skills give me the confidence to communicate with others. I
          am confident to communicate in four different languages and trying to
          learn more.
        </p>
        <div className="mb-6">
          <h3 className="text-xl">Professional Skills</h3>
          <div className="mt-4">
            {professionalSkills.map((skill, i) => (
              <span
                key={i}
                className="bg-primary-100 pt-1 pb-1 pl-3 pr-3 rounded-full text-white font-light mt-1 mr-2 mb-1 inline-block"
              >
                {skill}
              </span>
            ))}
          </div>
        </div>
        <div>
          <h3 className="text-xl">Languages</h3>
          <div className="mt-4">
            {languages.map((language, i) => (
              <img
                className="w-12 h-8 inline-block object-cover mr-4"
                src={languageImages[language]}
                alt={language}
                key={i}
              />
            ))}
          </div>
        </div>
      </div>
    </Panel>
  )
}
export default Skills
