import React from "react"

const SingleExperience = ({ experience }) => {
  const { address, description, duties, jobTitle, timePeriod } = experience
  const { companyName, street, cityCountry } = address
  return (
    <div className="singleExperience shadow rounded p-4 p-2 ">
      <div className="mb-6">
        <h4 className="text-lg font-bold mb-2">
          {jobTitle}{" "}
          <span className="text-gray-700 text-xs font-light">
            ({timePeriod})
          </span>
        </h4>
        <p className="text-md font-bold">{companyName}</p>
        <p>{street}</p>
        <p>{cityCountry}</p>
      </div>
      {description && <p className="mb-6">{description}</p>}
      <ul className="list-disc pl-4 ">
        {duties.map((duty, i) => (
          <li key={i}>{duty}</li>
        ))}
      </ul>
    </div>
  )
}

export default SingleExperience
