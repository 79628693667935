import React from "react"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import AboutMe from "../components/AboutMe"
import Experience from "../components/Experience"
import Degrees from "../components/Degrees"
import Skills from "../components/Skills"
import ContactMe from "../components/ContactMe"
import CopyRight from "../components/CopyRight"
import "./../../src/tailwind.css"
import Sidebar from "../components/Sidebar"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Sidebar />
    <div className="w-full md:w-8/12 lg:w-9/12 px-4 h-full">
      <AboutMe />
      <div className="h-4" />
      <Experience />
      <div className="h-4" />
      <Degrees />
      <div className="h-4" />
      <Skills />
      <div className="h-4" />
      <ContactMe />
      <div className="h-4" />
      <CopyRight />
      <div className="h-4" />
    </div>
  </Layout>
)

export default IndexPage
