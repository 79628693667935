import React from "react"
import Panel from "./Panel"
import Underline from "./Underline"

const AboutMe = () => {
  return (
    <Panel>
      <h2 className="text-2xl font-bold text-primary-100" id="aboutMe">
        About me
      </h2>
      <Underline />
      <p>
        A highly motivated, creative and a good team player with a strong vision
        to achieve successful outcomes. I possess a Masters degree in Computer
        Science and have 6+ years building developing and managing websites,
        applications and programs for various companies. I seek to secure the
        position of Front End Developer and hope to share my skills and
        experience with others. Soon I will link the apps/projects I worked on
      </p>
    </Panel>
  )
}
export default AboutMe
